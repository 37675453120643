@import "@/stylesheets/_mixin.scss";
@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";

.view-location-map-container{
    width: 100vw;
    height: 100vh;
    .adm-nav-bar{
        background-color: $base-color !important;
        color: $base-color;
    }
    .poi-info{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: #fff;
        .poi-title {
            width: 100%;
            padding: 0.2rem 0.15rem 0.3rem 0.15rem;
            box-sizing: border-box;
            background: #FFFFFF;
            border: 0 solid rgba(0,0,0,0.10);
            // box-shadow: 0 0 2px 0 rgb(0 0 0 / 15%);
            .poi-name {
                font-size: 0.17rem;
                color: #111111;
                display: block;
                margin-right: 0.7rem;
                word-wrap: break-word;
            }
            .poi-addr {
                font-size: 0.13rem;
                color: #666666;
                display: block;
                margin-top: 0.04rem;
                margin-right: 0.7rem;
                word-wrap: break-word;
            }
            .tonav {
                display: block;
                position: absolute;
                top: 50%;
                margin-top: -0.05rem;
                right: 0.16rem;
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 0.6rem;
                overflow: hidden;
                transform: translateY(-50%);
                i {
                    background: url('https://mapapi.qq.com/web/mapComponents/locationMarker/v/images/arrow_1.png') no-repeat;
                    background-size: 100%;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    border-radius: 0.6rem;
                }
                .tonavCover {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    border-radius: 0.6rem;
                }
            }
        }
    }
}
