@import "@/stylesheets/_base.scss";
.login-container{
  height: 100%;
  background: #fff;
  // .verify-btn{
  //   background: #eee;
  //   border-radius: 0.8rem;
  //   padding: 0.08rem;
  //   color: $base-color;
  //   font-size: 0.15rem;
  //   border: 0.01rem solid $base-color;
  // }
  .adm-list-item-content{
    padding-left: 0.12rem;
  }
  .login-btn{
    margin-top: 0.3rem;
  }
}