.distance-recently-wrapper{
    padding: 0.18rem 0.15rem 0.08rem 0.15rem;
    .head-title{
        font-size: 13px;
        line-height: 13px;
        color: #bdc0c5;
        margin-bottom: 0.18rem;
    }
    .adm-button{
        font-size: 0.13rem;
        margin: 0 0.05rem 0.1rem 0;
    }
    border-bottom: 1px solid #eee;
}