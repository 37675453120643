@import "../../stylesheets/_base.scss";
.app-footer-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f7f7f7;
  a {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0.05rem 0;
    font-size: 0.15rem;
    i {
      font-size: 0.18rem;
    }
    &.actived {
      color: $base-color;
    }
  }
}
