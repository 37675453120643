@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";
.edit-user-info-container{
  min-height: 100%;
  background: #f4f4f4;
  .header-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    .adm-nav-bar{
      background-color: $base-color !important;
      color: #fff;
    }
  }
  
  .adm-image-uploader-upload-button-wrap{
    .adm-image-uploader-cell{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .adm-list-item-content{
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

}

