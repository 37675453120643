@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";
.comment-complete-container{
  min-height: 100%;
  background: #575353;
  .header-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    .adm-nav-bar{
      background-color: $base-color !important;
      color: #fff;
      .comment-title{
        color: #24292f;
        font-size: 0.16rem;
      }
      .comment-film{
        font-size: 0.12rem;
      }
    }
  }
  .content-container{
    padding: 0 0.15rem;
    margin-top: 0.3rem;
    padding-bottom: 0.1rem;
    .content-wrapper{
      border-radius: 0.1rem;
      background: #fff;
      padding-bottom: 0.1rem;
      .user-info{
        height: 1rem;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 0.3rem;
        .avatar{
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
        }
        .right-wrapper{
          margin-left: 0.2rem;
          // background: #ccc;
          .nickname{
            .ping{
              color: #999;
            }
          }
          .num{
            color: #999;
          }
          
        }
      }
      .score-wrapper{
        position: relative;
        height: 1rem;
        border-top: 0.01rem dotted #ccc;
        border-bottom: 1px dotted #ccc;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        .film-name{
          position: absolute;
          left: 50%;
          top: -0.12rem;
          transform: translateX(-50%);
          background: #fff;
        }
        .star{
          margin-top: 0.2rem;
        }
        .score{
          margin-top: 0.1rem;
          font-size: 0.2rem;
          font-weight: 800;
          color: $base-color;
        }
      }
      .comment-content{
        padding: 0.15rem 0.1rem;
      }
      .poster-img{
        margin: 0.15rem 0.1rem;
      }
    }
  }
  

}

