@import "@/stylesheets/_base.scss";
.app-film-container{
  .custom-swiper-wrapper{
    position: relative;
    .tag-film-name{
      position: absolute;
      left: 0.07rem;
      top: 0.18rem;
      // width: 0.48rem;
      // height: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0.12rem;
      z-index: 100;
      // background: #000;
      // opacity: 0.4;
      // border-radius: 0.15rem;
      padding: 0.05rem 0.05rem;
      .icon-down{
        margin-left: 0.02rem;
      }
      .mask{
        position: absolute;
        z-index: -1;
        opacity: 0.4;
        width: 100%;
        height: 100%;
        background: #000;
        border-radius: 0.15rem;
      }

      .location-show-box{
        position: absolute;
        left: 0;
        top: 0.4rem;
        width: 2.2rem;
        // padding: 0.03rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.05rem;
        
        .location-mask{
          position: absolute;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0.6;
          border-radius: 0.05rem;
          z-index: -1;
        }
        .left-txt{
          margin-left: 0.03rem;
        }
        
        .top-arrow{
          position: absolute;
          top: -0.2rem;
          left: 0.1rem;
          border-width: 0.1rem;
          border-color: transparent;
          border-style: solid;
          border-bottom: 0.1rem solid #000;
          opacity: 0.6;
        }

      }


    }
  }
  .navbar-wrapper{
    .city-name{
      margin-right: 0.05rem;
    }
  }
  
  .float-tabs-component{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 10;
  }
    
}