@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";
.order-detail-container{
    min-height: 100vh;
    background-color: #f4f4f4;
    .content-container{
      background-color: #64663e;
      // height: 60vh;
      box-shadow: 0.1rem -0.1rem 0.5rem 0.6rem #64663e;
      padding: 0 10px;
      .header-content{
        // height: 70px;
        // background-color: #494c2f;
        // border-top-left-radius: 0.1rem;
        // border-top-right-radius: 0.1rem;
        .head-top{
          background-color: #494c2f;
          border-top-left-radius: 0.1rem;
          border-top-right-radius: 0.1rem;
          height: 50px;
          margin-top: 0.1rem;
          display: flex;
          align-items: center;
          padding: 0.1rem;
          color: #fff;
          .cinema-name-wrapper{
            display: flex;
            flex: 1;
            .cinema-name{
              flex: 1;
              width: calc(100vw - 2rem);
              @include ellipsis-one()
            }
          }
        }
        .content-wrapper{
          border-radius: 0.1rem;
          background-color: #fff;
          border-bottom: 1px solid #eee;
          .content{
            padding: 0.1rem;
            // height: 70px;
            background-color: #fff;
            border-radius: 0.1rem;
            position: relative;
            overflow: hidden;
            // border-bottom: 1px solid #eee;
            .top-half-circle{
              position: absolute;
              left: 50%;
              top: -0.2rem;
              transform: translateX(-50%);
              width: 30px;
              height: 30px;
              background-color: #494c2f;
              border-radius: 50%;
            }
            .top-wrapper{
              .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left{
                  color: #666;
                  width: calc(100vw - 1.2rem);
                  .film-name{
                    font-weight: bold;
                    color: #000;
                  }
                }
                .img{
                  border-radius: 0.1rem;
                }
              }
              .bot{
                margin-top: 0.05rem;
                .date-hall{
                  color:#6d6d6d;
                  font-size: 0.14rem;
                  .date{
                    margin-right: 0.5rem;
                  }
                }
                .time-seat{
                  font-size: 0.14rem;
                  font-weight: bold;
                  color: #000;
                  .time{
                    margin-right: 20px;
                  }
                  .hall-section-seat{
                    font-size: 0.14rem;
                    // display: flex;
                    // flex-flow: wrap;
                    .hall-name{
                        margin-right: 10px;
                    }
                    .section-item{
                        display: flex;
                        flex-flow: wrap;
                    }
                    .section-name{
                        color:#e9ea95;
                        margin-right: 0.05rem;
                    }
                    .seat{
                        margin-right: 0.05rem;
                    }
                  }
                }
              }
            }
            .separator-wrapper{
              width: 100%;
              height: 0.5rem;
              position: relative;
              // left: 50%;
              // overflow: hidden;
              // transform: translateX(-50%);
              .circle{
                width: 20px;
                height: 20px;
                background-color: #64663e;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                border-radius: 50%;
              }
              .left-circle{
                left: -0.2rem;
              }
              .line{
                width: 110%;
                height: 0.005rem;
                background-color: #64663e;
                left: 50%;
                transform: translateX(-50%);
              }
              .right-circle{
                right: -0.2rem;
              }
            }
            .qr-code{
              // margin-top: 0.8rem;
              h3{
                color: #000;
                font-weight: bold;
              }
              .code{
                display: flex;
                justify-content: center;
                margin-top: 0.3rem;
                position: relative;
                .already-complete{
                  position: absolute;
                  bottom: -0.1rem;
                  right: 0.8rem;
                  color: $base-color;
                  font-size: 1rem;
                }
              }
              .verify-code{
                height: 0.5rem;
                line-height: 0.5rem;
                text-align: center;
                border: 0.01rem solid #ccc;
                border-radius: 0.1rem;
                margin-top: 0.2rem;
                .code-num{
                  color: #000;
                  font-weight: bold;
                }
                .text-line-through{
                  color: #ccc;
                  text-decoration:line-through
                }
              }
            }
          }
          .save-qr{
            width: 0.8rem;
            height: 0.8rem;
            display: flex;
            flex-flow: column;
            align-items: center;
            // line-height: 0.5rem;
            justify-content: center;
            font-size: 0.14rem;
          }
        }
        

        
        
      }
    }

    .order-detail-card{
      background-color: #fff;
      margin: 0.12rem;
      border-radius: 0.1rem;
      padding: 0.15rem 0.1rem;
      .order-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
          color: #000;
          font-weight: bold;
        }
        .val{
          font-size: 0.13rem;
          display: flex;
          align-items: center;
        }

        

      }
      .detail-item{
        margin-top: 0.05rem;
        .label{
          font-size: 0.14rem;
        }
        .val{
          color: #000;
          font-size: 0.14rem;
        }
      }
    }

    .order-detail--mask-container{
      .content{
          min-height: 2rem;
          padding-bottom: 0.5rem;
          .premium{
              color: #ccc;
          }
      }
  
  }

}

