@mixin ellipsis-one() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin ellipsis-two() {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}