@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";
.app-cinema-page{
  .skeleton-box{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #fff;
    z-index: 1000;
  }
  .header-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;
    .navbar-wrapper{
      font-size: 0.13rem;
      font-weight: bold;
      .city-name{
        margin-right: 0.03rem;
      }
    }
    .adm-tabs-tab{
      font-size: 0.15rem;
    }
  }
  
}
.adm-grid-item{
  text-align: center;
}
.area-wrapper{
  border: 0.01rem solid #eee;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 0.03rem;
  @include ellipsis-one();
  &.active{
    border: 0.01rem solid $base-color;
    color: $base-color;
  }

}

