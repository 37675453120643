@import "@/stylesheets/_mixin.scss";
@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";

.buy-ticket-detail-box{
    .adm-nav-bar{
        background-color: $base-color !important;
        color: #fff;
    }
    .header-wrapper{
        padding: 0.125rem 0.125rem 0.5rem 0.125rem;
        background-color: $base-color;
        display: flex;
        // align-items: center;
        color: #fff;
        .right-wrapper{
            flex: 1;
            margin-left: 0.1rem;
            .title-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                h3{
                    //color: #fff;
                    width: 1.5rem;
                    @include ellipsis-one();
                } 
                .count-price{
                    font-size: 0.12rem;
                    text-align: right;
                }
            }
            .date-time-language-type{
                font-size: 0.14rem;
                margin-top: 0.1rem;
                .date{
                    margin-right: 0.1rem;
                }
                .time{
                    margin-right: 0.1rem;
                }
                .language-type{
                    margin-right: 0.1rem;
                }
            }
            .cinema-box{
                font-size: 0.14rem;
            }
            .hall-section-seat{
                font-size: 0.14rem;
                display: flex;
                flex-flow: wrap;
                .hall-name{
                    margin-right: 10px;
                }
                .section-item{
                    display: flex;
                    flex-flow: wrap;
                }
                .section-name{
                    color:#e9ea95;
                    margin-right: 0.05rem;
                }
                .seat{
                    margin-right: 0.05rem;
                }
            }
            
        }
    }
    
    .content-box{
        background: #fff;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        margin-top: -0.3rem;
        padding-top: 0.3rem;
        .adm-list-item-content-main{
            min-height: 0.45rem;
        }
    }
    .bottom-bar{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.1rem;
        padding-bottom: calc(constant(safe-area-inset-bottom) + 0.1rem);
        padding-bottom: calc(env(safe-area-inset-bottom) + 0.1rem);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 0.01rem solid #f3f3f3;
        .price{
            font-size: 0.18rem;
            color: $base-color;
            font-weight: bold;
        }
        .price::before{
            content: '¥';
            font-size: 0.12rem;
        }
        .right-wrapper{
            display: flex;
            align-items: center;
            .detail-box{
                .txt{
                    margin-right: 0.05rem;
                }
                margin-right: 0.1rem;
            }
        }
    }
    
    .order-detail--mask-container{
        .content{
            min-height: 2rem;
            padding-bottom: 0.5rem;
            .premium{
                color: #ccc;
            }
        }
    
    }

    .expire-time-wrapper{
      position: fixed;
      top: 0.2rem;
      right: 0;  
      padding: 0 0.1rem 0 0.05rem;
      background-color: #ccc;
      border-top-left-radius: 0.1rem;
      border-bottom-left-radius: 0.1rem;
      display: flex;
      align-items: center;
      .time{
          margin-left: 0.05rem;
      }
    }
}



