@import "@/stylesheets/_base.scss";
.custom-swiper-container {
    width: 100%;
    height: 2.1rem;
    .swiper-pagination{
        text-align: right;
        padding-right: 0.1rem;
        .swiper-pagination-bullet{
        background: none;
        border: 0.01rem solid #fff;
        opacity: 1;
        }
        .swiper-pagination-bullet-active{
        background-color: $base-color;
        border: 0.01rem solid $base-color;
        }
    }
}  