@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";
.comment-list-container{
  min-height: 100%;
  // background: #f4f4f4;
  .header-wrapper{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    .adm-nav-bar{
      background-color: $base-color !important;
      color: #fff;
      .comment-title{
        color: #fff;
        font-size: 0.16rem;
      }
      .comment-film{
        font-size: 0.12rem;
      }
    }
  }
  .comment-tip-wrapper{
    height: 0.5rem;
    padding: 0.12rem;
    display: flex;
    justify-content: space-between;
    
  }


  .unfold-reply-btn{
    height: 0.3rem;
    font-size: 0.13rem;
    color: #605d5d;
    padding-bottom: 0.1rem;
    .btn{
      margin-left: 0.03rem;
      .icon{
        margin-left: 0.05rem;
      }
    }
  }
  
  .reply-input-wrapper{
    padding: 0.1rem 0.2rem;
    border-top: 0.01rem solid #eee;
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

}

