@import "@/stylesheets/_base.scss";
.film-detail-container {
  .skeleton-box{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }
  margin-bottom: 0.8rem;
  .adm-nav-bar-left{
    .adm-nav-bar-back{
      border-radius: 50%;
      background: #fff;
      padding:0.06rem;
      opacity: 0.3;
      .adm-nav-bar-back-arrow{
            margin-right: 0
      }
    }
  }
  .header-wrapper {
    // height: 2.8rem;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 0.4rem;
    .blur-bg{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: -1;
      width: 200%;
      height: 200%;
      // background: url('https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2798491980,1963709037&fm=26&gp=0.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      filter: blur(0.3rem);
      -webkit-filter: blur(0.3rem);
    }
    .header-content{
      width: 100%;
      // height: 100%;
      padding: 0.1rem 0.14rem;
      .film-detail{
        display: flex;
        .image{
          width: 1rem;
          height: 1.5rem;
        }
        .right-content{
          height: 1.5rem;
          margin-left: 0.15rem;
          .film-name{
            font-size: 0.22rem;
            font-weight: 400;
            color: #fff;
          }
          .cate-film{
            margin-top: 0.15rem;
            color: #fff;
            .play-type-name{
              margin-left: 0.08rem;
            }
          }
          .play-area{
            color: #fff;
            font-size: 0.14rem;
          }
          .show-date{
            width: calc(100vw - 1.5rem);
            color: #fff;
            font-size: 0.14rem;
          }
          .want-see{
            margin-top: 0.16rem;
            font-size: 0.14rem;
            color: #fff;
            .num{
              font-size: 0.18rem;
              font-weight: 400;
              color: var(--adm-color-warning);
              margin-right: 0.1rem;
            }
          }
        }
      }
      .write-comment{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0.05rem 0.1rem;
        padding-right: 0.05rem;
        background-color: #9e8e8e;
        border-radius: 0.1rem;
        margin-top: 0.15rem;
        .left-box{
          color: #fff;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          .daiyanjing-icon{
            font-size: 0.3rem;
            margin-right: 0.1rem;
          }
        }
        .right-btn{
          
        }
      }
      .thumb-up-write-comment{
        display: flex;
        justify-content: space-between;
        margin-top: 0.15rem;
        .thumb-up-write-comment-btn{
          width: 45%;
          height: 0.35rem;
          line-height: 0.35rem;
          text-align: center;
          background-color: #968e8e;
          border-radius: 0.02rem;
          color:#fff;
          user-select: none;
          .left-icon{
            margin-right: 0.1rem;
          }
        }
        .thumb-up-btn{
          
        }
        .write-comment-btn{
         
        }
      }
    }
  }
  .abstract-detail{
    padding: 0.12rem 0.15rem 0.15rem 0.15rem;
    .abstract{
      font-size: 0.13rem;
      margin-top: 0.12rem;
      color: #000;
      .text{
        overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        position: relative;
        // animation-name:  animationname;
        // animation-duration: 4s;
        .label{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0px;
          background-color: #fff;
          padding-top: 0.06rem;
          display: flex;
          justify-content: center;
        }
        .label .down-out-line{
          display: block;
        }
        .label .up-out-line{
          display: none;
        }
      }
      .label-input{
        display: none;
      }
      .label-input:checked + .text {
        -webkit-line-clamp: 999;
      }
      .label-input:checked + .text .label .down-out-line {
        display: none;
      }
      .label-input:checked + .text .label .up-out-line {
        display: block;
      }
    }
  }
  .separator{
    height: 0.1rem;
    background-color: #f4f4f4;

  }
  .actors-wrapper{
    padding-left: 0.15rem;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    padding-bottom: 0.1rem;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .actor-item{
      width: 0.85rem;
      margin-right: 0.1rem;
      text-align: center;
      .img{
        width: 0.85rem;
        height: 1rem;
      }
      .name{
        margin-top: 0.1rem;
        font-size: 12px;
        color: #191a1b;
      }
      .role{
        font-size: 10px;
        color: #797d82;
      }
    }
  }
  .still-wrapper{
    width: 100%;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    padding-bottom: 0.1rem;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    padding-left: 0.15rem;
    .still-item{
      width: 1.5rem;
      height: 1rem;
      background-color: #f4f4f4;
      margin-right: 0.1rem;
      .photo-wrapper{
        width: 1.5rem;
        height: 1rem;
        position: relative;
        overflow: hidden;
        .photo{
          width: 1.5rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
        }
      }
      
    }
  }
  
  .edit-mine-comment-btn{
    color: $base-color;
    height: 0.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .show-comment-btn{
    height: 0.5rem;
    line-height: 0.5rem;
    text-align: center;
    color: $base-color;
    font-weight: bold;
    border-top: 0.01rem solid #ccc;
  }
  .select-seat-buy-btn{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }


  .unfold-reply-btn{
    height: 0.3rem;
    font-size: 0.13rem;
    color: #605d5d;
    padding-bottom: 0.1rem;
    .btn{
      margin-left: 0.1rem;
      .icon{
        margin-left: 0.05rem;
      }
    }
  }
  .reply-input-wrapper{
    padding: 0.1rem 0.2rem;
    border-top: 0.01rem solid #eee;
    background: #fff;
    // position: fixed;
    // left: 0;
    // right: 0;
    // bottom: 0;
  }
}

// @keyframes animationname {
//   from {-webkit-line-clamp: 3;}
//   to {-webkit-line-clamp: 999;}
// }