@import "@/stylesheets/_base.scss";
.set-container{
    .adm-nav-bar{
        background-color: $base-color !important;
        color: #fff;
    }
    .adm-list-item-content-main{
        font-size: 0.16rem;
        color: #666666;
    }
    .login-out-btn-wrapper{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 0.4rem;
        // padding-bottom: calc(constant(safe-area-inset-bottom)+ 0.2rem);
        // padding-bottom: calc(env(safe-area-inset-bottom)+0.2rem);
        .login-out-btn{
        }
    }
}