.still-mask-container{
    width: 100%;
    height: 100vh;
    background: #fff;
    .img-list{
      width: 100%;
      display: flex;
      flex-flow: wrap;
      .img-item{
        width: 33.33333%;
        // height: 1.24rem;
        .img-wrapper{
          width: 100%;
          height: 1.24rem;
          position: relative;
          overflow: hidden;
          .image{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            width: 100%;
            // min-height: 100%;
            border-right: 0.01rem solid #fff;
            border-bottom: 0.01rem solid #fff;
          }
        }
        
      }
      .img-item:nth-child(3n){
        border-right: none;
      }
    }
  }