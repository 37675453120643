.progress-bar-and-rate-bar{
    display: flex;
    justify-content: space-between;
    .progress-bar-wrapper{
        width: 63%;
        display: flex;
        background-color: #9e8e8e;
        border-radius: 0.05rem;
        // padding: 0.05rem;
        .pro-left{
            padding: 0.05rem;
            border-right: 0.01rem solid #b09999;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            font-size: 0.14rem;
            
            .daiyanjing-icon{
                font-size: 0.2rem;
            }
            .koubei{
                font-weight: bold;
            }
        }
        .pro-right{
            flex: 1;
            padding: 0.05rem;
            .pro-bar-item{
                display: flex;
                align-items: center;
                font-size: 0.12rem;
                color: #fff;
                .score-scope{
                    width: 0.4rem;
                    text-align: right;
                }
                .pro-bar{
                    flex: 1;
                    margin: 0 0.05rem;
                }
                .score-pro{
                    width: 0.45rem;
                    text-align: left;
                    font-size: 0.12rem;
                    text-align: right;
                }
            }
        }
    }
    .rate-bar-wrapper{
        width: 35%;
        background-color: #9e8e8e;
        padding: 0.05rem;
        border-radius: 0.05rem;
        color:#fff;
        font-size: 0.12rem;
        .pingfen{
        }
        .rate-score{
            display: flex;
            color: var(--adm-color-warning);
        }

    }
}