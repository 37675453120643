@import "@/stylesheets/_base.scss";
.app-mine-user-page{
    .header-wrapper{
        height: 2rem;
        // background: url('http://zly.imgresource.com.cn/siteLogo/mineBg.png');
        background: $base-color;
        background-size: cover;
        padding: 0.64rem 0 0 0.22rem;
        margin-top: -0.44rem;
        display: flex;
        align-items: center;
        color: #fff;
        margin-bottom: 0.1rem;
        .img{
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 50%;
            border: 0.02rem solid #fff;
        }
        .content{
            margin-left: 0.2rem;
            .balance{
                // color: $base-color;
            }
            .balance::before{
                content: '¥';
                font-size: 0.12rem;
                margin-right: 0.05rem;
            }
        }
    }
}