@import "@/stylesheets/_base.scss";
@import "@/stylesheets/_mixin.scss";
.infinite-scroll-content{
  .no-content-text{
    height: 2rem;
    line-height:2rem;
    font-size: 0.13rem;
  }
}

 