@import "@/stylesheets/_mixin.scss";
@import "@/stylesheets/_base.scss";

.cinema-detail-container {
  .skeleton-box{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }
  .header-title {
    height: 0.44rem;
    padding: 0 0.15rem;
    text-align: center;
    font-size: 0.17rem;
    color: #191a1b;
    line-height: 0.44rem;
  }
  .services-wrapper{
    padding: 0.05rem 0.15rem 0.15rem 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.01rem solid #eee;
    box-sizing: border-box;
    .tags{
      .tag-item{
        margin: 0 0.05rem 0.05rem 0;
        padding: 0 0.02rem;
      }
    }
    .arrow-rigght{
      margin-bottom: 0.05rem;
    }
  }
  .location-wrapper{
    height: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.15rem;
    justify-content: space-between;
    .addr{
      margin: 0 0.15rem 0 0.1rem;
      font-size: 0.14rem;
      color: #191a1b;
      @include ellipsis-one();
    }
    .phone-icon{
      border-left: 0.01rem solid #eee;
      padding-left: 0.05rem;
    }
  }
  .wrapper-container{
    width: 100%;
    height: 1.6rem;
    position: relative;
    overflow: hidden;
    .bg-img{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      // background: url('https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2798491980,1963709037&fm=26&gp=0.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      filter: blur(0.3rem);
      -webkit-filter: blur(0.3rem);
    }
    .swiper-container {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 0.15rem;
      .swiper-wrapper{
        align-items: flex-end;
      }
      .swiper-slide {
        text-align: center;
        font-size: 0.18rem;
        // background: #ccc;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        .img-wraper{
          transition: 500ms;
          height: 1.3rem  !important;
          width: 1rem !important;
          padding-top: 0.15rem;
          img{
            display: block;
            height: 100% !important;
            width: 100% !important;
          }
        }
        
      }
      .swiper-slide-active{
        .img-wraper{
          padding-top: 0;
          width: 1.5rem !important;
          img{
            display: block;
            height: 100% !important;
            width: 100% !important;
          }
        }
      }
    }
    .bottom-arrow{
      position: absolute;
      bottom: -0.01rem;
      left: 50%;
      transform: translateX(-50%);
      border: 0.10rem solid transparent;
      border-bottom: 0.10rem solid #fff;
    }
  }
  .film-info-wrapper{
    padding: 0.15rem 0.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info{
      flex: 1;
      display: flex;
      flex-flow: column;
      align-items: center;
      font-size: 0.16rem;
      color: #191a1b;
      padding-right: 0.05rem;
      .film-name-score{
        font-size: 0.15rem;
        margin-bottom: 0.1rem;
        .score{
          font-size: 0.16rem;
          margin-left: 0.05rem;
          color: #ffb232;
          .unit{
            font-size: 0.12rem;
          }
        }
      }
      .plot{
        text-align: center;
        width: calc(100vw - 0.8rem);
        height: 0.18rem;
        color: #797d82;
        font-size: 0.13rem;
        @include ellipsis-one()
      }
    }
    
  }
  .adm-tabs-tab{
    font-size: 0.14rem;
  }
  
}

.cell-item-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.15rem;
  border-bottom: 0.01rem solid #eee;
  .left-box{
    flex: 1;
    display: flex;
    // align-items: center;
    .left-item{
      font-size: 0.15rem;
      color: #191a1b;
      margin-right: 0.3rem;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .bottom{
        font-size: 0.13rem;
        color: #797d82;
        margin-top: 0.02rem
      }
    }
  }
  .right-box{
    width: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price{
      font-size: 0.15rem;
      color: $base-color;
      &::before{
        content: "¥";
        font-size: 0.12rem;
        margin-right: 0.05rem;
      }
    }
    .btn{
      width: 0.5rem;
      height: 0.25rem;
      color: $base-color;
      text-align: center;
      line-height: 0.25rem;
      border: 0.01rem solid $base-color;
      font-size: 0.12rem;
      border-radius: 0.04rem;
      &::before{
        content: '购票';
      }
    }
  }
  &:nth-last-child(1){
    border-bottom: none;
  }
}

.cinema-detail-service-mask-container{
  width: 100%;
  height: 100%;
  background: #fff;
  .header-wrapper{
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000000;
    background-color: #fff;
  }
  .service-default-box{
    padding: 0 0.3rem 0.3rem 0.3rem;
    animation-name: example;
    animation-duration: 0.6s;
    // animation-delay: 0.3s;
    position: relative;
    // top: -100%;
    overflow-x: hidden;
    height: calc(100vh - 90px);
    .service-item{
      display: flex;
      margin-top: 0.2rem;
      .tag-wrappr{
        margin-top: 0.03rem;
        // min-width: 85px;
        text-align: right;
        .tag{
          height: 0.20rem;
          line-height: 0.2rem;
          margin-right: 0.1rem;
          
          padding: 0.0rem 0.02rem;
        }
      }
      
      .service-content{
        // line-height: 0.18rem;
        flex: 1;
        color: #797d82;
        font-size: 0.14rem;
      }
    }
    @keyframes example {
      from { 
        top: -4rem;
      }
      to { 
        top: 0;
      }
    }
  }
  
  
  
}